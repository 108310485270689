import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

const GlobalStyle = createGlobalStyle`
  ${normalize()}
  * {
    box-sizing: inherit;
    backface-visibility: inherit
  }

  html {
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    scroll-behavior: auto;
    /* height: 100%;
    min-height: 100%; */
  }
  body {
    background-color: #fff;
    height: 100%;
  }
  body, h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
    color: black;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    /* max-height: 100%; */
  }

  .container {
    display: block;
    height: auto;
    width: 100%;
    margin: 0 auto;
  }

  figure {
    margin: 0;
  }
`
export default GlobalStyle
