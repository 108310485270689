import React from "react"
import PropTypes from "prop-types"
import GlobalStyle from "../util/global"

import Header from "./header"

const Layout = ({ children, location, inView }) => {
  return (
    <>
      <GlobalStyle />
      <Header
        isHomePage={location.pathname === "/" ? true : false}
        inView={inView}
      />
      {children}
    </>
  )
}

Layout.defaultProps = {
  location: {},
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
