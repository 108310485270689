import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { animated, useSpring } from "react-spring"

import LogoSVG from "./logoSVG"

const HeaderWrapper = styled.header`
  position: fixed;
  padding: 0 40px;
  top: 40px;
  left: 0;
  right: 0;
  justify-content: space-between;
  display: flex;
  width: 100%;
  @media (max-width: 1240px) {
    padding: 0 20px;
  }
  @media (max-width: 879px) {
    top: 0;
    height: 60px;
    background-color: #fff;
    z-index: 100;
  }
`

const LogoHeading = styled.h1`
  margin: 0;
  text-align: center;
  overflow: hidden;
  max-width: 10vw;
  padding: 0;
  padding-right: 20px;

  a {
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 10px;
    width: 100%;
    height: auto;
  }

  svg {
    width: 100%;
  }
  @media (max-width: 1240px) {
    padding-right: 10px;
  }
  @media (max-width: 879px) {
    padding-top: 22px;
    max-width: 140px;
  }
  @media (max-width: 360px) {
    max-width: 100px;
  }

  .second-logo {
    @media (max-width: 879px) {
      max-width: 40px;
    }
  }
`

const Navigation = styled.nav`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  width: 10vw;

  a {
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    margin-right: 10px;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 3px;
    white-space: nowrap;
    position: relative;
    padding-left: 20px;
    padding-top: 20px;
    display: block;
    writing-mode: vertical-lr;
    text-orientation: upright;
    -webkit-text-orientation: upright;
    border-top: 8px solid transparent;

    @media (max-width: 1240px) {
      font-size: 0.6rem;
      padding-left: 20px;
      margin-right: 5px;
    }
    @media (max-width: 879px) {
      writing-mode: horizontal-tb;
      text-orientation: upright;
      padding-left: 20px;
      margin-right: 0;
    }
    @media (max-width: 572px) {
      :first-child {
        display: none;
      }
    }
  }
  @media (max-width: 879px) {
    justify-content: flex-end;
    width: 100%;
  }
`

const SocialBar = styled.nav`
  position: fixed;
  bottom: 20px;
  left: 0;

  ul {
    display: flex;
    list-style: none;
    flex-direction: column;
    padding-left: 40px;
    margin: 0;
    /* text-transform: uppercase; */
    li {
      margin-top: 20px;
      letter-spacing: 3px;
      font-weight: 600;
      transition: color 0.12s ease-in-out;
      &:nth-child(2) {
        &:hover {
          color: #8a3ab9;
        }
      }
      &:nth-child(1) {
        &:hover {
          color: #3b5998;
        }
      }
      a {
        text-decoration: none;
        color: inherit;
      }
    }
    @media (max-width: 1240px) {
      padding-left: 20px;
    }
  }
`

export const NavLink = ({ to, text }) => (
  <Link activeStyle={{ borderTop: "8px solid #000" }} to={to} aria-label={text}>
    {text}
  </Link>
)

const Header = ({ isHomePage, inView }) => {
  const { opacity, transform, height } = useSpring({
    config: { mass: 5, tension: 2000, friction: 500 },
    from: {
      opacity: 0,
      transform: "translate3d(0, 20px, 0)",
      height: "0",
    },
    opacity: !inView ? 1 : 0,
    transform: !inView ? "translate3d(0, 0, 0)" : "translate3d(0, 20px, 0)",
    height: !inView ? 20 : 0,
  })
  return (
    <>
      <HeaderWrapper>
        <LogoHeading>
          {isHomePage ? (
            <AnimatedLink to="/" aria-label="home page">
              <animated.div style={{ opacity, transform, overflow: "hidden" }}>
                <animated.div style={{ height, overflow: "hidden" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 176.5 9.5"
                  >
                    <g id="Warstwa_2" data-name="Warstwa 2">
                      <g id="Warstwa_1-2" data-name="Warstwa 1">
                        <path d="M7.1,1.1,6.5,2.5a5.41,5.41,0,0,0-2.7-.8c-.9,0-1.5.3-1.5,1,0,2,4.9.9,4.9,4.2,0,1.6-1.4,2.6-3.5,2.6A5.39,5.39,0,0,1,0,8L.7,6.7A4.49,4.49,0,0,0,3.8,8c1.1,0,1.7-.4,1.7-1.1C5.5,4.9.6,6,.6,2.7.6,1.1,1.9.2,3.9.2A5,5,0,0,1,7.1,1.1Z" />
                        <path d="M36.9,4.8A4.66,4.66,0,0,1,32,9.4a4.66,4.66,0,0,1-4.9-4.6A4.66,4.66,0,0,1,32,.2,4.6,4.6,0,0,1,36.9,4.8Zm-8.2,0A3.33,3.33,0,0,0,32,8.1a3.25,3.25,0,1,0,0-6.5A3.12,3.12,0,0,0,28.7,4.8Z" />
                        <path d="M64.6,1.6l-.9,1.1a3.7,3.7,0,0,0-2.5-1.2,3.24,3.24,0,0,0-3.3,3.2,3.18,3.18,0,0,0,3.3,3.2,3.41,3.41,0,0,0,2.5-1.1l.9,1a5.09,5.09,0,0,1-3.5,1.5,4.65,4.65,0,1,1,0-9.3A5.69,5.69,0,0,1,64.6,1.6Z" />
                        <path d="M93.9,4.8A4.66,4.66,0,0,1,89,9.4a4.72,4.72,0,0,1-4.9-4.6A4.66,4.66,0,0,1,89,.2,4.55,4.55,0,0,1,93.9,4.8Zm-8.1,0a3.33,3.33,0,0,0,3.3,3.3,3.25,3.25,0,1,0,0-6.5A3.07,3.07,0,0,0,85.8,4.8Z" />
                        <path d="M120,7.9V9.3h-5.5V.1H116V7.9Z" />
                        <path d="M141.7,7.3l-.9,2.1h-1.6l4-9.2h1.6l4,9.2h-1.6l-.9-2.1ZM144,1.8l-1.7,4.1h3.5Z" />
                        <path d="M173,.2c1.9,0,3.1.9,3.1,2.3a2,2,0,0,1-1.6,2,2.28,2.28,0,0,1,2,2.3c0,1.6-1.3,2.5-3.3,2.5H169V.1h4ZM170.6,4H173c1,0,1.6-.5,1.6-1.2S174,1.6,173,1.6h-2.4Zm0,4H173c1.2,0,1.9-.5,1.9-1.3s-.7-1.3-1.9-1.3h-2.4Z" />
                      </g>
                    </g>
                  </svg>
                </animated.div>
              </animated.div>
            </AnimatedLink>
          ) : (
            <div className="second-logo">
              <Link to="/" aria-label="home page">
                <LogoSVG />
              </Link>
            </div>
          )}
        </LogoHeading>
        <Navigation>
          {isHomePage ? (
            <a aria-label="e-commerce" href="#e-commerce">
              e-com
            </a>
          ) : (
            <NavLink to={"/"} text="home" />
          )}
          <NavLink to="/contact/" text="contact" />
          <NavLink to="/about-us/" text="about us" />
        </Navigation>
      </HeaderWrapper>
      <SocialBar>
        <ul>
          <li>
            <a
              aria-label="facebook"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/SOCOlabCreativeAgency/"
            >
              fb
            </a>
          </li>
          <li>
            <a
              aria-label="instagram"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/socolab_creativeagency/"
            >
              ig
            </a>
          </li>
        </ul>
      </SocialBar>
    </>
  )
}
const AnimatedLink = animated(Link)

export default Header
